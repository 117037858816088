import { PageContext } from "vike/types";
import { useDocumentTitle } from "../../shared/DocumentTitle";
import SeoImage from "../../shared/seo/graphics/default_1139x628.png";
import { LandingPageTemplate } from "../LandingPage";

const title = "NAF Kjøpekontrakt";
const description =
  "Kjøpekontrakten er salgets viktigste dokument og gir alle parter en tryggere handel. Velg mellom Enkel-kontrakt med digital signatur eller oppgrader til Premium med BankID-signering, gebyrfri betaling med Vipps og vi ordner eierskifte hos Statens Vegvesen.";

export const documentProps: PageContext["config"]["documentProps"] = {
  title,
  description,
  image: {
    url: SeoImage,
    width: "1139",
    height: "628",
  },
};

export function DefaultLandingPage() {
  useDocumentTitle(title);

  return (
    <LandingPageTemplate
      createContractCard={{
        title,
        description,
      }}
    />
  );
}
